import React, { Suspense, useEffect, useState } from 'react';
import { addDays, set } from 'date-fns';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import "../components/Employees/Schedule/Schedule.css";
import SuccessAlerts from '../components/SuccessAlert';
import ErrorAlerts from '../components/ErrorAlert';
import MobileScheduleHeader from '../components/Employees/Schedule/MobileScheduleHeader';

const ScheduleHeader = React.lazy(() => import('../components/Employees/Schedule/ScheduleHeader'));
const ScheduleTable = React.lazy(() => import('../components/Employees/Schedule/ScheduleTable'));

const Schedule = () => {
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [scheduleArray, setScheduleArray] = useState([]);
  const [allScheduleArray, setAllScheduleArray] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [summary, setSummary] = useState([]);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const getMonday = (d) => {
    // Create a new date object to avoid modifying the input
    let date = new Date(d);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let day = date.getDay();

    // Calculate the difference to Monday
    // If it's Sunday (0), we need to go back 6 days
    // For any other day, we go back (day - 1) days
    let diff = date.getDate() - day + (day === 0 ? -6 : 1);

    // Set the date to Monday
    date.setDate(diff);

    // Return the date at the start of the day
    return new Date(date.setHours(0, 0, 0, 0));
  }

  const getSunday = (d) => {
    // Create a new date object to avoid modifying the input
    let date = new Date(d);

    // Get the day of the week
    let day = date.getDay();

    // Calculate the difference to Sunday
    // If it's already Sunday (0), we don't need to change the date
    // For any other day, we add (7 - day) days
    let diff = date.getDate() + (day === 0 ? 0 : 7 - day);

    // Set the date to Sunday
    date.setDate(diff);

    // Return the date at the end of the day
    return new Date(date.setHours(23, 59, 59, 999));
  }


  const [startDate, setStartDate] = useState(getMonday(new Date()));
  const [endDate, setEndDate] = useState(getSunday(new Date()));
  const [totalPublished, setTotalPublished] = useState(0);
  const [totalUnPublished, setTotalUnPublished] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(false);

  const getWeekNumber = (d) => {
    // Create a new date object and set it to UTC to avoid timezone issues
    const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

    // Return week number padded with leading zero if needed
    return weekNo < 10 ? `0${weekNo}` : `${weekNo}`;
  };

  const [week, setWeek] = useState(moment().format('YYYY-[W]WW'));

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const fetchEmployees = () => {
    setEmployeeLoading(true);
    axios.get(
      `${process.env.REACT_APP_API_BACKEND}/api/schedule/employees`,
      config
    ).then((res) => {
      setEmployees(res.data.employees);
      setEmployeeLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const getRoster = (week) => {
    setScheduleLoading(true);
    setScheduleArray([]);
    setAllScheduleArray([]);
    const data = {
      "week": week,
    }
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/schedule`, data, config).then((res) => {
      setTotalPublished(res.data.totalPublishedShifts);
      setTotalUnPublished(res.data.totalUnpublishedShifts);
      const sortedScheduleItems = [];
      res.data.scheduleItems.forEach((department) => {
        const sortedDepartment = department.scheduleItems.sort((a, b) => {
          return a.published - b.published;
        });
        sortedScheduleItems.push({ department: department.department, scheduleItems: sortedDepartment });
      });
      setAllScheduleArray(sortedScheduleItems);
      setSummary(res.data.summary);
      if (search === "") {
        setScheduleArray(sortedScheduleItems);
      } else {
        filterScheduleArray(search, sortedScheduleItems);
      }
      setTimeout(() => {
        setScheduleLoading(false);
      }, 500);
    });
  }

  const handleWeekChange = (week) => {
    setWeek(week);
    const currentDate = moment(week, 'YYYY-[W]WW');
    const firstDay = currentDate.clone().isoWeekday(1);
    const lastDay = currentDate.clone().isoWeekday(7);
    
    setStartDate(firstDay.toDate());
    setEndDate(lastDay.toDate());
    localStorage.setItem("week", week);
    getRoster(week);
  };

  const handlePreviousWeek = () => {
    const currentDate = moment(startDate);
    const prevWeekStart = currentDate.subtract(7, 'days');
    const year = prevWeekStart.isoWeekYear();
    const weekNum = prevWeekStart.isoWeek().toString().padStart(2, '0');
    const newWeek = `${year}-W${weekNum}`;
    
    setWeek(newWeek);
    localStorage.setItem("week", newWeek);
    
    const firstDay = prevWeekStart.clone().isoWeekday(1);
    const lastDay = prevWeekStart.clone().isoWeekday(7);
    
    setStartDate(firstDay.toDate());
    setEndDate(lastDay.toDate());
    getRoster(newWeek);
  };

  const handleNextWeek = () => {
    const currentDate = moment(startDate);
    const nextWeekStart = currentDate.add(7, 'days');
    const year = nextWeekStart.isoWeekYear();
    const weekNum = nextWeekStart.isoWeek().toString().padStart(2, '0');
    const newWeek = `${year}-W${weekNum}`;
    
    setWeek(newWeek);
    localStorage.setItem("week", newWeek);
    
    const firstDay = nextWeekStart.clone().isoWeekday(1);
    const lastDay = nextWeekStart.clone().isoWeekday(7);
    
    setStartDate(firstDay.toDate());
    setEndDate(lastDay.toDate());
    getRoster(newWeek);
  };

  const filterScheduleArray = (searchTerm, scheduleItems = allScheduleArray) => {
    const filteredArray = scheduleItems.map((department) => {
      const filteredDepartment = department.scheduleItems.filter((schedule) => {
        const fullName = `${schedule.employee.employeeDetail.fname.toLowerCase()} ${schedule.employee.employeeDetail.lname.toLowerCase()}`;
        return (
          schedule.employee.employeeDetail.fname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          schedule.employee.employeeDetail.lname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          schedule.employee.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          fullName.includes(searchTerm.toLowerCase())
        );
      });

      if (filteredDepartment.length > 0) {
        return { department: department.department, scheduleItems: filteredDepartment };
      }
      return null;
    }).filter(Boolean);

    setScheduleArray(filteredArray);
  };

  const handleEmployeeSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm === "") {
      setScheduleArray(allScheduleArray);
    } else {
      filterScheduleArray(searchTerm);
    }
  };

  useEffect(() => {
    fetchEmployees();
    const storedWeek = localStorage.getItem("week");
    if (storedWeek) {
      setWeek(storedWeek);
      const currentDate = moment(storedWeek, 'YYYY-[W]WW');
      const firstDay = currentDate.clone().isoWeekday(1);
      const lastDay = currentDate.clone().isoWeekday(7);
      setStartDate(firstDay.toDate());
      setEndDate(lastDay.toDate());
      getRoster(storedWeek);
    } else {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const weekNum = getWeekNumber(currentDate);
      const currentWeek = `${year}-W${weekNum}`;
      setWeek(currentWeek);
      getRoster(currentWeek);
      localStorage.setItem("week", currentWeek);
    }
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      {success && <SuccessAlerts success={success} />}
      {error && <ErrorAlerts error={error} />}
      <Suspense fallback={<div>Loading...</div>}>
        {!isMobile ?
          <ScheduleHeader
            fetch={getRoster}
            handlePreviousWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            handleWeekChange={handleWeekChange}
            week={week}
            totalPublished={totalPublished}
            totalUnPublished={totalUnPublished}
            search={search}
            setSearch={setSearch}
            loading={scheduleLoading}
            setSuccess={setSuccess}
            setError={setError}
            allScheduleArray={allScheduleArray}
            setScheduleArray={setScheduleArray}
            summary={summary}
            handleEmployeeSearch={handleEmployeeSearch}
          />
          :
          <MobileScheduleHeader
            fetch={getRoster}
            handlePreviousWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            handleWeekChange={handleWeekChange}
            week={week}
            totalPublished={totalPublished}
            totalUnPublished={totalUnPublished}
            search={search}
            setSearch={setSearch}
            loading={scheduleLoading}
            setSuccess={setSuccess}
            setError={setError}
            allScheduleArray={allScheduleArray}
            setScheduleArray={setScheduleArray}
            summary={summary}
          />
        }
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <ScheduleTable
          fetch={getRoster}
          startDate={startDate}
          endDate={endDate}
          data={scheduleArray}
          loading={scheduleLoading}
          employees={employees}
          employeeLoading={employeeLoading}
          fetchEmployees={fetchEmployees}
          setSuccess={setSuccess}
          setError={setError}
          summary={summary}
          setSearch={setSearch}
          handleSearch={handleEmployeeSearch}
        />
      </Suspense>
    </div>
  );
}

export default Schedule;